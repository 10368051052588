import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IHealthCheckFeatureState, IHealthCheckSharedState } from './interfaces';
import { IHealthCheckState } from './interfaces/health-check-state';

export const getHealthCheckState = (state: { healthCheckState: IHealthCheckState }) =>
  state.healthCheckState;

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectHealthCheckState = createFeatureSelector<
  IHealthCheckFeatureState & IHealthCheckSharedState
>('healthCheck');

export const selectHealthCheckEntityState = createSelector(
  selectHealthCheckState,
  getHealthCheckState
);
export const selectNextShutDown = createSelector(
  selectHealthCheckEntityState,
  (state) => state.nextShutDown
);
